import React from "react";
import "./Banner.css";

import { Link } from "react-router-dom";

const Banner = ({banner}) => {
  
  return (
    <section className={`banner-wrapper ${banner?.class && banner?.class}`} style={{backgroundImage:`url(${banner.banner})`}}>
      <div className="banner-section relative">
       
        {/* <img src={homeBanner} alt="" /> */}
        <div className="overlayer">
          <div className="wrapper h100 center">
            <div className="overlayer-text text-center">
              <h2> {banner?.h1} <br />{banner?.h2 &&banner?.h2}</h2>
              {banner?.p && <p>{banner?.p}</p>}
             { banner?.button && <div className="button"><Link to={banner?.buttonLink} className="btn btn-1">{banner?.button}</Link></div>}
            </div>
          </div>
        </div>
      </div>
      <svg className="round-banner" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
	<path class="elementor-shape-fill" d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z"></path>
</svg>
    </section>
  );
};

export default Banner;
