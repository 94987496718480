import React from 'react';
import './Contact.css';
import Banner from '../../Componants/Banner/Banner';
import banner from "../../assets/contactbanner.jpg";

const Contact = () => {

    const bannar = {
        banner: banner,
        h1: "Contact Us",
        button: "Let's Work Together!",
        buttonLink: "/contact",
        p: "You deserve to be seen. We are here to achieve your goal.",
      };
  return (
   <>
    <Banner banner={bannar} />
    <div className='contact-wrapper'>
      <div className="wrapper d-flex justify-between  gap-50">
        <div className="contactLeft">
            <div className="addressCard d-flex gap-20">
                <div className="icon self-center">
                <i class="fa-solid fa-location-dot"></i>
                </div>
                <div className="text">
                    <h3>Address</h3>
                    <p>Opposite Marriott Hotel, Miramar, Panjim Goa - 403001</p>

                </div>

            </div>
            <div className="addressCard d-flex gap-20">
                <div className="icon self-center">
                <i class="fa-solid fa-envelope-circle-check"></i>
                </div>
                <div className="text">
                    <h3>Email</h3>
                    <p>support@instapaymax.com</p>

                </div>

            </div>
            <div className="addressCard d-flex gap-20">
                <div className="icon self-center">
                <i class="fa-solid fa-phone"></i>
                </div>
                <div className="text">
                    <h3>Phone</h3>
                    <p>+91-8799906286</p>

                </div>

            </div>

        </div>
       
       
        <div className="contactRight">
            <from>
                <div className="from-field">
                    <input type="text" placeholder='Your Name' />
                </div>
                <div className="from-field">
                    <input type="text" placeholder='Your Email' />
                </div>
                <div className="from-field">
                    <textarea name="" id="" placeholder='Your Message'  >
                    </textarea>
                </div>
                <div className="from-field">
                    <button type="submit" className='btn btn-2'>Submit Now!</button>
                </div>

            </from>
        </div>

      </div>
     
      </div>
      <div className="map-wrapper">
        <div className="wrapper">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7689.94519793027!2d73.809336!3d15.485906000000002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbfc1deb634510f%3A0x729d35e8b2b04e98!2sCasa%20Del%20Sol!5e0!3m2!1sen!2sus!4v1693399071510!5m2!1sen!2sus" width="100%" height="100" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
   
   </>
  );
}

export default Contact;
