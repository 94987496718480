import "./App.css";
import { Routes, Route } from "react-router-dom";

import Header from "./Componants/Header/Header";
import Footer from "./Componants/Footer/Footer";
import Home from "./Pages/Home/Home";
import Contact from "./Pages/Contact/Contact";
import About from "./Pages/About/About";
import MobileAppSdk from "./Pages/MobileAppSdk/MobileAppSdk";
import WebCkeckout from "./Pages/WebCkeckout/WebCkeckout";
import PaymentGateways from "./Pages/PaymentGateways/PaymentGateways";
import Login from "./Pages/LoginRegister/Login";
import Register from "./Pages/LoginRegister/Register";
import ForgotPassword from "./Pages/LoginRegister/ForgotPassword";
import PaymentRecurring from "./Pages/PaymentRecurring/PaymentRecurring";
import InstantRefund from "./Pages/InstantRefund/InstantRefund";
import PrivacyPolicies from "./Pages/PrivacyPolicies/PrivacyPolicies";
import PaymentLink from "./Pages/PaymentLink/PaymentLink";
import PaymentButtons from "./Pages/PaymentButtons/PaymentButtons";
import CancellationPolicy from "./Pages/CancellationPolicy/CancellationPolicy";
import TermsandCondition from "./Pages/Terms&Condition/TermsandCondition";
import GrievanceRedressal from "./Pages/GrievanceRedressal/GrievanceRedressal";
import ScrollTop from "./Componants/ScrollTop/ScrollTop";

function App() {
  return (
    <div className="App">
      <Header />
      <ScrollTop />
       <div className="main-wrapper">

       <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/mobile-app-sdk" element={<MobileAppSdk />} />
        <Route path="/web-checkout" element={<WebCkeckout />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/payment-gateway" element={<PaymentGateways />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/privacy-policy" element={<PrivacyPolicies />} />
        <Route path="/recurring-payments" element={<PaymentRecurring />} />
        <Route path="/instant-refund" element={<InstantRefund />} />
        <Route path="/payment-links" element={<PaymentLink />} />
        <Route path="/payment-buttons" element={<PaymentButtons />} />
        <Route path="/cancellation-policy" element={<CancellationPolicy />} />
        <Route path="/terms-of-use" element={<TermsandCondition />} />
        <Route path="/grievance-redressal" element={<GrievanceRedressal />} />
      </Routes>
       </div>

      <Footer />
    </div>
  );
}

export default App;
