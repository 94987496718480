import React from "react";

import "./GrievanceRedressal.css";
import Banner from "../../Componants/Banner/Banner";
import gif from "../../assets/intro.gif";
import { Link } from "react-router-dom";
import banner from "../../assets/Grievance-banner.jpg";

const GrievanceRedressal = () => {
  const bannar = {
    banner: banner,
    h1: "Grievance Redressal",
  };
  return (
    <div className="GrievanceRedressal-wrapper">
      <Banner banner={bannar} />
      <div className="introduction-wrapper">
        <div className="wrapper">
          <h2 className="h2 mb-20">Introduction</h2>
          <p className="text-center mb-20">
            Instapaymax’s board-approved policies for handling grievances of
            customers are classified as under:
          </p>
          <div className="img">
            <img className="w-100" src={gif} alt="gif" />
          </div>

          <div className="desc">
            <p className="mb-20">
              All of the above policy matters bind all participants i.e.
              Customer, Merchant and Payment Aggregator (Instapaymax), and shall
              contain detailed explanation of the types of disputes, the process
              of dealing with them, the compliance required, responsibilities of
              all the stakeholders, documentation, reason codes and procedures
              for addressing the grievance, turn-around-time for each stage,
              etc. Multilevel support and nodal officer details We have also
              provided the details of multi-level support as well as details of
              the Nodal Officer who would be responsible for regulatory and
              customer grievance handling functions.
            </p>
            <p>Disposal of Complaints</p>
            <p>Listing of common queries and resolutions with TATs:</p>
          </div>
        </div>
      </div>
      <div className="CustomerQueriesWrapper">
        <div className="wrapper">
          <table>
            <thead>
              <tr>
                <th>Customer Queries</th>
                <th>Resolution</th>
                <th>TAT</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Customer enquires about the transaction status.</td>
                <td>
                  We provide all details such as the order no, PA reference
                  number, date of transaction, transaction amount, merchant URL
                  and status of transaction.
                </td>
                <td>
                  24 hours (For queries received between 10 am-7 pm Monday to
                  Sunday)
                </td>
              </tr>
              <tr>
                <td>
                  Transaction successful, but no response received from
                  merchant.
                </td>
                <td>
                  We provide all the information to the customer, including the
                  merchant details. Additionally, we forward the same mail to
                  the merchant providing services, keeping the customer in CC.
                </td>
                <td>
                  24 hours (For queries received between 10 am-7 pm Monday to
                  Sunday)
                </td>
              </tr>
              <tr>
                <td>Refund issues</td>
                <td>
                  We provide all the refund details to the customers, with bank
                  reference number and ARN number for customers to check further
                  with his bank.
                </td>
                <td>
                  We provide all the refund details to the customers, with bank
                  reference number and ARN number for customers to check further
                  with his bank.
                </td>
              </tr>
              <tr>
                <td>Refund not reflecting in the customer’s account.</td>
                <td>
                  We recheck the funds status and update the customer OR raise
                  the issue with the concerned bank. The updates received are
                  shared with the customer.
                </td>
                <td>
                  24 hrs to 48 hrs (working days) post receiving update from
                  concerned entity.
                </td>
              </tr>
              <tr>
                <td>Amount debited but transaction not found.</td>
                <td>
                  We search for the transaction with details such as: Email Id
                  used while performing the transaction, Date and amount of
                  transaction, And, if still not found, then ask customers to
                  contact their concerned bank for more details or other
                  reference nos.
                </td>
                <td>
                  24 hrs to 48 hrs (working days) post receiving update from
                  concerned entity.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="DisputeResolutionWrapper">
        <div className="wrapper text-left">
          <h2 className="h2 mb-20 text-left">Dispute Resolution Mechanism</h2>
          <h3 className="mb-20 f-25">
            Customers can raise dispute on shipped orders for the following
            reasons:
          </h3>
          <ul>
            <li>Transaction amount is different</li>
            <li>Request is made for the copy of the receipt</li>
            <li>Merchandise is defective or damaged</li>
            <li>
              Alleged fraudulent transaction investigated by the acquiring bank
            </li>
            <li>Multiple charges for the same order</li>
            <li>
              Merchant agreed to issue refund on merchandise returned or for any
              other reason
            </li>
            <li>Merchandise not as advertised</li>
            <li>Customer did not engage in or authorize this transaction</li>
            <li>Merchandise has not been delivered</li>
            <li>
              Cardholder is not in possession of the card used for the
              transaction
            </li>
            <li>Service not received from merchant</li>
            <li>Order was cancelled</li>
            <li>Customer paid for order by other payment method</li>
          </ul>

          <p className="mb-30">
            <strong>How we manage disputes at </strong>Instapaymax Only the
            Customer or Instapaymax can raise disputes. The merchant can view
            all disputes raised on their transactions in their Disputes Listing
            Page. They can also provide updates as well as submit required
            documents for the dispute via a 3-way messaging facility between
            Instapaymax , the merchant and the customer.
          </p>
          <p>1. Supporting Documents</p>
          <p className="mb-30">
            Instapaymax may request for a set of supporting documents for cases
            that need further investigation. These documents could include the
            Authorization Letter from the customer, Cancellation Policy, etc.
            Merchants can respond by attaching the required documents with a
            clarification message using our messaging facility. This can be
            uploaded from the Dispute Management module.
          </p>
          <p>2. Dispute Resolution</p>
          <p className="mb-30">
            Instapaymax team can either <strong>close</strong> the dispute in
            the merchant’s favour or allow chargeback to the customer. The
            Dispute status can be: <strong>‘Open’</strong>,{" "}
            <strong>‘Chargeback’ </strong>, <strong>‘Closed’</strong> and ‘
            <strong>Open and Hold</strong>’. After the dispute status has been
            set to ‘Open’, or ‘Open and Hold’, Instapaymax can update its status
            to ‘Closed’ or <strong>‘Chargeback’</strong>. If the open dispute is
            confirmed as chargeback, Instapaymax will debit the dispute amount
            in the customer’s favour and set the status as{" "}
            <strong>‘Chargeback’</strong>. Similarly, if the open dispute is
            closed in favour of the merchant, Instapaymax will update the
            dispute status as <strong>‘Closed’</strong>.
          </p>

          <p>3. Resolution of ‘Open and Hold’ Cases</p>
          <p className="mb-30">
            Instapaymax designates the status as ‘Open and Hold’ for disputed
            cases that need further investigation and sets the amount as well as
            the end date for resolution. The amount set aside for resolution at
            this stage cannot be greater than the disputed amount.{" "}
            <strong>
              {" "}
              Different types of resolution for ‘Open and Hold’ cases:
            </strong>{" "}
          </p>
          <ul style={{ marginLeft: "40px", marginBottom: "30px" }}>
            <li>
              When the time limit set for the ‘<strong>Open and Hold</strong>’
              case is over and the dispute status has not changed, Instapaymax
              will release the amount held against the dispute and close the
              dispute.
            </li>
            <li>
              After further investigation, when Instapaymax changes the status
              for the <strong>‘Open and Hold’</strong> case to{" "}
              <strong>‘Chargeback’</strong>, the amount set aside for resolution
              (the balance amount payable) is treated as the chargeback amount
              and debited from the merchant’s account in favour of the customer.
            </li>
            <li>
              When Instapaymax changes the status for the
              <strong>‘Open and Hold’</strong> case to <strong>‘Closed’</strong>
              , the amount set aside for resolution is ruled in the merchant’s
              favour i.e. credited to their account.
            </li>
          </ul>
          <p>Refund Processing Timelines</p>
          <p className="mb-30">
            Instapaymax has a very stringent mechanism to deal with refunds of
            failed transactions (transactions charged to customer but not
            returned to Payment Aggregator and, in turn, to merchant, hence no
            services are rendered). As per the merchant’s line of business, they
            can opt to configure their account to reverse such transactions or
            get it updated as a successful transaction post reconciliation, and
            continue to provide services.{" "}
            <strong>We can reconcile dropped transactions in 2 ways:</strong>
          </p>

          <div
            className="box mb-20"
            style={{ padding: "8px 10px", border: "1px solid #e2e8f0" }}
          >
            <p>
              <strong>a)</strong> We initiate server-to-server API calls with
              banks on periodic basis throughout the day to fetch the status of
              transactions. Such cases are sent to bank for reversal on T+1
            </p>
          </div>
          <div
            className="box mb-30"
            style={{ padding: "8px 10px", border: "1px solid #e2e8f0" }}
          >
            <p>
              {" "}
              <strong>b)</strong> For banks who do not provide the API-based
              reconciliation mechanism, we reconcile with the offline files
              received from the banks on the next working day. Thus refunds for
              such transactions are sent the next day post reconciliation or T+1
              post reconciliation.{" "}
            </p>
          </div>

          <div
            className="address"
            style={{ lineHeight: "30px", marginBottom: "50px" }}
          >
            <p>Customer Grievances Nodal Officer</p>
            <p>
              {" "}
              <strong>Mr. Prakash Ravindran</strong>{" "}
            </p>
            <p>
              <strong>Email:</strong>
              <Link to="mailto:nodal@instapaymax.com">
                nodal@Instapaymax.com{" "}
              </Link>
            </p>
            <p>
              <strong>Phone:</strong>+91-8799906288
            </p>
          </div>

          <div
            className="level-box"
            style={{ marginBottom: "50px", color: "#7A7A7A" }}
          >
            <h2 className="h2 text-left mb-20">Level 1 : Support</h2>
            <p>
              The Customer or merchant can send email to{" "}
              <Link to="mailto:support@paysharp.in">
                {" "}
                support@instapaymax.com
              </Link>{" "}
              with the specific issue related to their grievance. The customer /
              merchant should provide all the necessary details related to
              grievance. Instapaymax team will respond within 48 hours.
              Instapaymax may ask for additional details regarding the complaint
              to the customer/merchant, to provide better service the customer /
              merchant should respond to the same email thread. If the customer
              or merchant is not satisfied with the final resolution they can
              escalate to the next level.
            </p>

            <div className="address" style={{ margin: "50px 0 0" }}>
              <p>
                Email:{" "}
                <Link to="mailto:support@paysharp.in">
                  support@instapaymax.com
                </Link>
              </p>
              <p>Contact No.: 91-8799906286</p>
              <p>10:00 AM – 06:00 PM (Monday to Saturday)</p>
            </div>
          </div>

          <div
            className="level-box"
            style={{ marginBottom: "50px", color: "#7A7A7A" }}
          >
            <h2 className="h2 text-left mb-20">Level 2 : Support</h2>
            <p>
              {" "}
              In case the complaint is not resolved or customer is not satisfied
              with the resolution of level 1 can escalate the complaint to the
              Level 2 support with receipt of complaint. A response may either
              contain the resolution or an intimation that the Company requires
              more time to examine the complaint. Even if the customer /
              merchant is not satisfied with the solution provided, the customer
              can go for the next level of escalations.
            </p>

            <div className="address" style={{ margin: "50px 0 0" }}>
              <p>Email: complaints@instapaymax.com</p>
              <p>Contact No.: 91-8799906286</p>
              <p>10:00 AM – 06:00 PM (Monday to Saturday)</p>
            </div>
          </div>

          <div
            className="level-box"
            style={{ marginBottom: "50px", color: "#7A7A7A" }}
          >
            <h2 className="h2 text-left mb-20">
              Level 3 : Nodal Officer Assistance
            </h2>
            <p>
              In the event that the customer does not receive a proper response,
              within the prescribed timelines of 10 business days of lodging the
              complaint, it may approach the ‘Nodal Officer’ of the Company, at
              the following contact details:{" "}
            </p>

            <p style={{ margin: "50px 0 0" }}>Nodal Officer:</p>

            <div className="address" style={{ margin: "50px 0" }}>
              <p>Mr. Prakash Ravindran</p>
              <p>Instapaymax </p>
              <p>
                325 2nd Floor, Casa Del Sol Wing 3 Opposite Marriott Hotel
                Miramar Panjim Goa -403001
              </p>
              <p className="mb-30">
                Email:{" "}
                <Link to="mailto:nodal@instapaymax.com">
                  nodal@instapaymax.com
                </Link>
              </p>
              <p>Phone : +91 8799906288</p>
            </div>
          </div>

          <div
            className="level-box"
            style={{ marginBottom: "50px", color: "#7A7A7A" }}
          >
            <h2 className="h2 text-left mb-20">
              Level 4 : Integrated Ombudsman
            </h2>
            <p>
              If the complainant is not satisfied even after redressal provided
              by the Nodal Officer, the complainant may lodge the complaint with
              the Ombudsman as per the Integrated Ombudsman Scheme, 2021 of RBI
              within 30 days of redressal provided by the Nodal Officer. The
              complainant can lodge their grievance on the ‘Complaint Management
              System’ (‘CMS’) portal of the RBI.The contact details and
              procedure for approaching the Ombudsman under Integrated Ombudsman
              Scheme, 2021 can be referred to here:{" "}
              <Link to="https://cms.rbi.org.in/">https://cms.rbi.org.in/ </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrievanceRedressal;
