import React from "react";
import "./WebCkeckout.css";
import Banner from "../../Componants/Banner/Banner";
import img9 from "../../assets/img-9.jpg";
// import banner from "../../assets/payment-recurring-background.jpg";
import banner from "../../assets/finetecgbackground.jpg";
import background from "../../assets/background.svg";
import '../../Componants/Banner/Banner.css';
import img1 from '../../assets/wenckeout-banner.jpg';

const WebCkeckout = () => {
  const bannar = {
    banner: banner,
    h1: "Best payment experience for your website with web checkout",
    p: "Optimize your website payments experience. Accept digital payments from 150+ methods & get industry best success rates.",
    class:"webCkeckout"
  };
  return (
    <div className="webCkeckout-wrapper">
     <section className={`banner-wrapper `} style={{backgroundImage:`url(${banner})`}}>
      <div className="banner-section relative">
       
         <img className="banner-wrapper" src={background} alt="" /> 
        <div className="overlayer">
          <div className=" center">
           
            <div className="finetech-wrapper">
          <div className=" center gap-50">
            <div className="left">
               <img className="w-100" src={img1} alt="" />
            </div>
            <div className="right">
              <h3 className="h3">Best payment experience for your website with web checkout</h3>
              <p>
              Optimize your website payments experience. Accept digital payments from 150+ methods & get industry best success rates.
              </p>
             

            </div>

          </div>

        </div>
          </div>
          
        </div>
      </div>
    
    </section>
     
     
      <div className="webCkeckout-wrapper webCkeckout-top">
        <div className="wrapper d-flex gap-50">
          <div className="desc font-20">
            <h3 className="h3">
              Accept Every Payment And Delight Every Customer
            </h3>
            <p>
              Cater every customer with 150+ payment options across Credit
              Cards, Debit Cards, Net Banking, EMIs, BNPL, UPI & Wallet.
            </p>
            <ul>
              <li>Supports all devices and browser</li>
              <li>Delight customers with 150+ payment modes</li>
              <li>Get a fast checkout experience</li>
              <li>Industry leading success rates</li>
            </ul>
          </div>
          <div className="img">
            <img src={img9} alt="img9" />
          </div>
        </div>
      </div>
      <div className="webCkeckout-bottom text-center">
        <h2>A Web Checkout For All Businesses</h2>
        <h3 className="h3">Integration Made Easier</h3>
        <div className="wrapper d-flex gap-50">


          
          <div class="flip-card">
            <div class="flip-card-inner center">
              <div class="flip-card-front center">
                <div>
                  <div className="icon"><i class="fa-solid fa-truck-fast"></i></div>
                  <h2>Merchant Hosted Checkout</h2>
                  
                </div>
              </div>
              <div class="flip-card-back center">
                <div>
                <p>The checkout is hosted on your servers.</p>
                <p>Full control on UI & UX with customization.</p>
                <p>For PCI:DSS compliant businesses.</p>
                </div>
              </div>
            </div>
          </div>

          <div class="flip-card">
            <div class="flip-card-inner center">
              <div class="flip-card-front center">
                <div>
                 <div className="icon"> <i class="fa-regular fa-calendar-check"></i></div>
                <h2>Instapaymax Hosted Checkout</h2>
                </div>
              </div>
              <div class="flip-card-back center">
                <div>
               
                <p>The checkout is hosted on PayU servers. Enables faster go-live</p>
                <p>Customize the logo, color and themes for your brand</p>
                <p>For non-PCI:DSS compliant businesses</p>
                </div>
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </div>
  );
};

export default WebCkeckout;
