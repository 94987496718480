import React from 'react'
import "../PaymentLink/PaymentLink.css";
import paymentLink from "../../assets/payment-link.png"
import paymentLink2 from "../../assets/payment-link-2.jpg"
import "../PaymentRecurring/PaymentRecurring.css"
function PaymentLink() {
  return (
    <div>
      <div className='main'>
        <div className='main-header'>
        <svg className='payment-banner' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
	<path class="elementor-shape-fill" opacity="0.33" d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"></path>
	<path class="elementor-shape-fill" opacity="0.66" d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"></path>
	<path class="elementor-shape-fill" d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"></path>
</svg>
          <div className='wrapper'>
            <div className='payment-link-main-content bannerWrapper'>
              <div className='payment-link-main-content-left w-50 center'>
                <div className='wrapper'>
                  <div className='payment-link-content'>
                    <h1 className='payment-link-heading'>Accepting Payment Is Now Just A Link Away</h1>
                  </div>
                  <div className='payment-link-content'>
                    <p className='f-25 l-height'>No website? No problem! Share payment links and get paid in minutes. No coding required.</p>
                  </div>

                </div>


              </div>
              <div className='payment-link-main-content-right w-50'>
                <img src={paymentLink}></img>
              </div>
            </div>
          </div>
        </div>
        <div className='dtat'>
          <div className='wrapper'>
            <div className='payment-link-main-content'>
              <div className='payment-link-main-content-left w-50 center'>
                <div className='wrapper'>
                  <img src={paymentLink2}></img>
                </div>
              </div>
              <div className='payment-link-main-content-right w-50 center'>
                <div className='wrapper'>
                  <div className='payment-link-content'>
                    <h1 className='payment-link-heading'>Experience The Ease Of Accepting Payments</h1>
                  </div>
                  <div className='payment-link-content'>
                    <ul className='l-height f-color fw-600 f-21'>
                      <li>Instant online activation</li>
                      <li>150+ payment modes</li>
                      <li>Full control over refunds management</li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className='payment-link-cards-main'>
          <div className='wrapper'>
            <div className='payment-link-cards-heading'>
              <h1>Create Payments Link in 3 Simple Steps</h1>
            </div>
            <div className='payment-link-steps-cards'>
              <div className='payment-link-steps-cards-column'>
                <div className='wrapper'></div>
                <h1>STEP1</h1>
                <div className='cards-content'>
                  <h4>Log in to your Instapaymax dashboard and choose 'Payment Links'</h4>
                  <p>Alternatively, you can create a Payment Link via a mobile app.</p>
                </div>
              </div>
              <div className='payment-link-steps-cards-column'>
              <div className='wrapper'></div>
                <h1>STEP2</h1>
                <div className='cards-content'>
                <h4>Enter details of payment to be accepted</h4>
                <p>Enter shipping charges, due date, tax amount and more. Accept partial payments easily.</p>
                </div>
              </div>
              <div className='payment-link-steps-cards-column'>
              <div className='wrapper'></div>
                <h1>STEP3</h1>
                <div className='cards-content'>
                <h4>Send payment link and accept payment</h4>
                <p>Share payment link via WhatsApp, SMS or ChatBot and accept payments instantly.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentLink
